function CompatibleWithStrava() {
  return (
    <svg id="strava_outlined" width="193px" height="48px" viewBox="0 0 188.8 30.57" fill="#fff">
      <title>Strava API Logo</title>
      <path className="cls-1" d="M108.12,23.3a14.15,14.15,0,0,1-4.31-.64,9.54,9.54,0,0,1-3.44-1.91l2.7-3.21a8,8,0,0,0,2.59,1.36,9.31,9.31,0,0,0,2.7.41,2.13,2.13,0,0,0,1-.17,0.53,0.53,0,0,0,.3-0.47v0a0.63,0.63,0,0,0-.44-0.54,7.69,7.69,0,0,0-1.65-.45q-1.27-.26-2.43-0.61a8.35,8.35,0,0,1-2-.88,4.27,4.27,0,0,1-1.39-1.36,3.69,3.69,0,0,1-.52-2v0a4.78,4.78,0,0,1,.42-2,4.57,4.57,0,0,1,1.23-1.62,5.85,5.85,0,0,1,2-1.08,8.9,8.9,0,0,1,2.75-.39,12.87,12.87,0,0,1,3.85.52,9.18,9.18,0,0,1,3,1.55L112,13.12A7.57,7.57,0,0,0,109.68,12a7.93,7.93,0,0,0-2.26-.36,1.56,1.56,0,0,0-.83.17,0.51,0.51,0,0,0-.27.45v0a0.62,0.62,0,0,0,.41.52,7,7,0,0,0,1.6.45,22.37,22.37,0,0,1,2.64.62,7.8,7.8,0,0,1,2,.94,4.16,4.16,0,0,1,1.32,1.37,3.81,3.81,0,0,1,.46,1.94v0a4.69,4.69,0,0,1-.48,2.14A4.57,4.57,0,0,1,113,21.93a6.35,6.35,0,0,1-2.09,1A9.87,9.87,0,0,1,108.12,23.3Z"/>
      <path className="cls-1" d="M119,12.19h-4.45V7.92h13.95v4.27H124V23H119V12.19Z"/>
      <path className="cls-1" d="M129.16,7.92h7.38a10.1,10.1,0,0,1,3.33.46A5.54,5.54,0,0,1,142,9.64a4.61,4.61,0,0,1,1,1.55,5.48,5.48,0,0,1,.35,2v0a4.77,4.77,0,0,1-.8,2.8,5.5,5.5,0,0,1-2.18,1.81L143.9,23h-5.76l-2.85-4.32h-1.08V23h-5.05V7.92Zm7.23,7.19a2.32,2.32,0,0,0,1.42-.39,1.28,1.28,0,0,0,.52-1.08v0a1.23,1.23,0,0,0-.52-1.09,2.44,2.44,0,0,0-1.4-.36h-2.2v3h2.18Z"/>
      <polygon className="cls-1" points="173.11 16.52 176.4 23.02 181.24 23.02 173.11 6.97 164.98 23.02 169.82 23.02 173.11 16.52"/>
      <polygon className="cls-1" points="150.48 16.52 153.77 23.02 158.61 23.02 150.48 6.97 142.35 23.02 147.19 23.02 150.48 16.52"/>
      <polygon className="cls-1" points="161.8 14.41 158.5 7.92 153.66 7.92 161.8 23.97 169.93 7.92 165.09 7.92 161.8 14.41"/>
      <path className="cls-1" d="M11.65,20.69l1.12,0.22A2.71,2.71,0,0,1,12,22.64a2.34,2.34,0,0,1-1.72.66,2.52,2.52,0,0,1-2-.84A3.61,3.61,0,0,1,7.55,20a3.34,3.34,0,0,1,.8-2.39,2.54,2.54,0,0,1,1.91-.84,2.37,2.37,0,0,1,1.66.62A2.51,2.51,0,0,1,12.71,19l-1.1.17a1.38,1.38,0,0,0-1.34-1.43,1.44,1.44,0,0,0-1.16.56A2.64,2.64,0,0,0,8.65,20a2.83,2.83,0,0,0,.46,1.76,1.42,1.42,0,0,0,1.17.58Q11.45,22.31,11.65,20.69Z"/>
      <path className="cls-1" d="M17.93,23.11a2.74,2.74,0,0,1-1,.18,2.7,2.7,0,0,1-1-.19,2.5,2.5,0,0,1-1.48-1.62A4.75,4.75,0,0,1,14.2,20a3.33,3.33,0,0,1,.8-2.38,2.55,2.55,0,0,1,1.92-.85,2.52,2.52,0,0,1,1.9.85,3.36,3.36,0,0,1,.8,2.39,4.75,4.75,0,0,1-.23,1.53,2.63,2.63,0,0,1-.61,1A2.53,2.53,0,0,1,17.93,23.11Zm-2.17-1.37a1.46,1.46,0,0,0,2.32,0A2.89,2.89,0,0,0,18.52,20a2.64,2.64,0,0,0-.46-1.67,1.43,1.43,0,0,0-2.29,0A2.62,2.62,0,0,0,15.31,20,2.88,2.88,0,0,0,15.76,21.74Z"/>
      <path className="cls-1" d="M21.47,23.16v-6.3H22.7l1.89,4.39h0l1.87-4.39H27.7v6.3H26.64V18.83h0l-1.9,4.33H24.45l-1.9-4.33h0v4.33H21.47Z"/>
      <path className="cls-1" d="M29.91,23.16v-6.3h2.51a2.25,2.25,0,0,1,1.56.53,1.86,1.86,0,0,1,.59,1.46A1.86,1.86,0,0,1,34,20.29a2.23,2.23,0,0,1-1.57.53H31v2.34H29.91ZM31,19.84H32.4a1.14,1.14,0,0,0,.77-0.27,1,1,0,0,0,0-1.49,1.23,1.23,0,0,0-.77-0.24H31v2Z"/>
      <path className="cls-1" d="M39.32,23.16l-0.38-1.08H36.4L36,23.16H35l2.27-6.3h0.86l2.27,6.3H39.32ZM37.67,18.4l-1,2.77h1.92L37.68,18.4h0Z"/>
      <path className="cls-1" d="M42.44,23.16V17.84H40.56v-1h4.82v1H43.5v5.32H42.44Z"/>
      <path className="cls-1" d="M47,23.16v-6.3h1.06v6.3H47Z"/>
      <path className="cls-1" d="M50.3,23.16v-6.3h2.59a1.86,1.86,0,0,1,1.34.48,1.58,1.58,0,0,1,.5,1.19A1.32,1.32,0,0,1,54,19.75v0a1.8,1.8,0,0,1,.68.54,1.46,1.46,0,0,1,.28.92q0,1.93-2.31,1.93H50.3Zm1.06-3.72h1.39a1.06,1.06,0,0,0,.69-0.2,0.72,0.72,0,0,0,.24-0.59,0.74,0.74,0,0,0-.25-0.6,1.06,1.06,0,0,0-.7-0.21H51.36v1.6Zm0,2.74h1.58a1.89,1.89,0,0,0,.38-0.06A0.77,0.77,0,0,0,53.65,22a0.8,0.8,0,0,0,.19-0.28,1.06,1.06,0,0,0,.08-0.44,0.74,0.74,0,0,0-.35-0.73,2.44,2.44,0,0,0-1.08-.18H51.36v1.85Z"/>
      <path className="cls-1" d="M56.82,23.16v-6.3h1.06v5.32h2.65v1H56.82Z"/>
      <path className="cls-1" d="M62.15,23.16v-6.3h3.92v1H63.21v1.62h2.61v0.95H63.21v1.77h2.86v1H62.15Z"/>
      <path className="cls-1" d="M71.73,23.16l-1.21-6.3H71.6l0.86,4.44h0L74,16.86h0.6l1.48,4.44h0l0.85-4.44H78l-1.21,6.3H75.68l-1.42-4.32h0l-1.4,4.32H71.73Z"/>
      <path className="cls-1" d="M79.8,23.16v-6.3h1.06v6.3H79.8Z"/>
      <path className="cls-1" d="M84.36,23.16V17.84H82.48v-1h4.82v1H85.42v5.32H84.36Z"/>
      <path className="cls-1" d="M88.87,23.16v-6.3h1.06v2.59h2.38V16.86h1.06v6.3H92.31V20.37H89.93v2.79H88.87Z"/>
    </svg>
  );
}

export default CompatibleWithStrava;